<template>
  <b-form @submit="onSubmit">
    <b-card no-body v-if="unity" class="radius-top-0">
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <b class="text-secondary">{{ getMetaAction }}</b>
          <div class="d-flex flex-row-reverse">
            <b-button v-if="$can('unity:create')" type="submit" variant="primary"
              >Salvar</b-button
            >

            <router-link
              :to="{
                name: 'units_list'
              }"
              class="btn btn-outline-muted mr-2"
              >Cancelar</router-link
            >
          </div>
        </div>
      </template>

      <b-tabs pills card vertical class="edit-menu-sidebar">
        <b-tab active>
          <template #title>
            <font-awesome-icon
              icon="exclamation-triangle"
              class="text-warning"
              v-if="!validations.dados_basicos"
            />
            Dados Básicos
          </template>
          <DadosBasicos ref="dados_basicos" />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-form>
</template>

<script>
import DadosBasicos from "./Form/DadosBasicos.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { DadosBasicos },
  data: () => ({
    validations: {
      dados_basicos: true
    }
  }),
  computed: {
    ...mapState("unities", ["unity", "unities"]),

    getMetaAction() {
      return this.$route.meta.action;
    }
  },
  methods: {
    ...mapActions("unities", ["ActionSave"]),

    async onSubmit(event) {
      event.preventDefault();

      if (!this.isValid()) {
        this.$notifications.addNotification({
          message: "Os dados do formulário são inválidos",
          type: "danger"
        });
      } else {
        try {
          await this.ActionSave(this.unity);
          this.$emit("saved", this.unity.id);
          this.$notifications.addNotification({
            message: "Registro salvo com sucesso",
            type: "success"
          });
        } catch (error) {
          let error_message = "Erro ao salvar";

          this.$notifications.addNotification({
            message: error_message,
            type: "danger"
          });
        }
      }
    },
    isValid() {
      this.validations.dados_basicos = this.$refs.dados_basicos.isValid();
      return Object.values(this.validations).every(Boolean);
    },
  }
};
</script>

<style></style>
