<template>
  <div class="w-100 mb-4">
    <Header />
    <Form @saved="loadData" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Form from "../components/Form.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Header, Form },
  data: () => ({}),
  computed: {
    ...mapState("unities", ["unity"])
  },
  methods: {
    ...mapActions("unities", ["ActionNew", "ActionSetUnities"]),
    loadData(id) {
      this.$router.push({ name: "units_edit", params: { id: id } });
    },
  },
  async created() {
    this.ActionNew();
  }
};
</script>

<style lang="scss">
.vue-step-wizard {
  width: 100%;
}
</style>
