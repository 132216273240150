<template>
  <div>
    <b-form-group label="Nome:">
      <b-form-input
        v-model="unity.name"
        placeholder="Nome"
        :state="validateState('name')"
      ></b-form-input>
      <b-form-invalid-feedback
        >Nome é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>
    
    <b-form-group label="Iniciais:">
      <b-form-input
        v-model="unity.initials"
        placeholder="Iniciais"
        :state="validateState('initials')"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import { mask } from "vue-the-mask";

export default {
  components: {},
  mixins: [validationMixin],
  data: () => ({}),
  validations: {
    unity: {
      name: {
        required,
        minLength: minLength(3)
      },
      initials: {
        required,
        maxLength: maxLength(10)
      }
    }
  },
  computed: {
    ...mapState("unities", ["unity"])
  },
  methods: {
    ...mapActions("unities", ["ActionGetAll"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.unity[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.unity.$touch();
      return !this.$v.unity.$anyError;
    }
  },
  async created() {
    await this.ActionGetAll({});
  },
  directives: { mask }
};
</script>
